<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Client Information</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm"
                        iconPos="right" />
                </div>
            </div>
            <div class="card">
                <div style="position: relative" v-if="!showloaderpage">
                    <div class="p-py-4 p-px-3">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-4">
                                <label for="clientname">
                                    Client Name
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <InputText id="clientname" class="p-text-capitalize" v-model.trim="clientname"
                                    required="true" maxlength="20" autofocus
                                    :class="{ 'p-invalid': submitted && !clientname }"  />
                                <small class="p-invalid p-error" v-if="v$.clientname.$error">{{
                                    v$.clientname.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="clientshortname">
                                    Client Short Name
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <InputText id="clientshortname" class="p-text-capitalize" v-model.trim="clientshortname"
                                    required="true" maxlength="20" autofocus
                                    :class="{ 'p-invalid': submitted && !clientshortname }"  />
                                <small class="p-invalid p-error" v-if="v$.clientshortname.$error">{{
                                    v$.clientshortname.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="partyname">
                                    Party Name
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <Dropdown id="partyname" v-model="partyname" :options="partynameList"
                                    optionLabel="label" placeholder="Select ..." appendTo="body">
                                </Dropdown>
                                <small class="p-invalid p-error" v-if="v$.partyname.$error">{{
                                    v$.partyname.$errors[0].$message
                                }}</small>
                            </div>

                            <div class="p-field p-col-12 p-md-4">
                                <label for="officemobileno">Office Contact Number <span
                                        class="p-invalid p-error">*</span></label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">
                                        +91
                                    </span>
                                    <InputText id="officemobileno" v-model="officemobileno" autocomplete="off"
                                        maxlength="10" @keypress="onlyNumber" autofocus
                                        :class="{ 'p-invalid': submitted && !officemobileno }" />
                                </div>

                                <small class="p-invalid p-error" v-if="v$.officemobileno.$error">{{
                                    v$.officemobileno.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="officeemail">Office email ID <span
                                        class="p-invalid p-error">*</span></label>
                                <InputText id="email" v-model="officeemail" autocomplete="off" maxlength="50" autofocus
                                    :class="{ 'p-invalid': submitted && !officeemail }" />
                                <small class="p-invalid p-error" v-if="v$.officeemail.$error">{{
                                    v$.officeemail.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="state">
                                    State
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <Dropdown id="state" v-model="state" :options="stateList" optionLabel="label"
                                    placeholder="Select ..." appendTo="body" @change="onStateSelect">
                                </Dropdown>
                                <small class="p-invalid p-error" v-if="v$.state.$error">{{ v$.state.$errors[0].$message
                                    }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="city">
                                    City
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <MultiSelect id="city" v-model="city" :options="cityList" optionLabel="label"
                                    placeholder="Select Cities" mode="tags" :filter="true" class="multiselect-custom"
                                    appendTo="body"></MultiSelect>

                                <!-- <AutoComplete class="p-fluid" id="city" v-model="city" multiple
                                    :suggestions="citySuggestions" appendTo="body" @complete="searchCities"
                                    placeholder="Select ..." field="label" dropdown /> -->
                                <small class="p-invalid p-error" v-if="v$.city.$error">{{ v$.city.$errors[0].$message
                                    }}</small>
                            </div>

                            <div class="p-field p-col-12 p-md-4">
                                <label for="lokSabha">
                                    Lok Sabha
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <MultiSelect id="loksabha" v-model="loksabha" :options="loksabhaList"
                                    optionLabel="label" placeholder="Select Lok Sabha" mode="tags" :filter="true"
                                    class="multiselect-custom" appendTo="body">
                                </MultiSelect>
                                <!-- <AutoComplete class="p-fluid" v-model="loksabha" multiple
                                    :suggestions="loksabhaSuggestions" appendTo="body" @complete="searchLoksabha"
                                    placeholder="Select ..." field="label" dropdown /> -->
                                <small class="p-invalid p-error" v-if="v$.loksabha.$error">{{
                                    v$.loksabha.$errors[0].$message }}</small>
                            </div>

                            <div class="p-field p-col-12 p-md-4">
                                <label for="vidhanSabha">
                                    Vidhan Sabha
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <MultiSelect id="vidhansabha" v-model="vidhansabha" :options="vidhansabhaList"
                                    optionLabel="label" placeholder="Select Vidhan Sabha" mode="tags" :filter="true"
                                    class="multiselect-custom" appendTo="body"></MultiSelect>
                                <!-- <AutoComplete class="p-fluid" v-model="vidhansabha" multiple
                                    :suggestions="vidhansabhaSuggestions" appendTo="body" @complete="searchVidhansabha"
                                    placeholder="Select ..." field="label" dropdown /> -->
                                <small class="p-invalid p-error" v-if="v$.vidhansabha.$error">{{
                                    v$.vidhansabha.$errors[0].$message }}</small>
                            </div>

                            <div class="p-field p-col-12 p-md-4">
                                <label for="officeaddress">
                                    Office Address
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <Textarea id="officeaddress" class="p-text-capitalize" v-model="officeaddress"
                                    :autoResize="true" rows="1" cols="30" required="true" autofocus
                                    :class="{ 'p-invalid': submitted && !officeaddress }" maxlength="250" />
                                <small class="p-invalid p-error" v-if="v$.officeaddress.$error">{{
                                    v$.officeaddress.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label>Generate Invoice? <span class="p-invalid p-error"> *</span></label>
                                <div class="p-field-radiobutton">
                                    <RadioButton id="invoice_yes" name="option" v-bind:value="1"
                                        v-model="invoicegenerator" :checked="invoicegenerator == 1" />
                                    <label for="invoice_yes" class="p-mr-2">Yes</label>
                                    <RadioButton id="invoice_no" name="option" v-bind:value="0"
                                        v-model="invoicegenerator" :checked="invoicegenerator == 0" />
                                    <label for="invoice_no" class="p-mr-2">No</label>
                                </div>
                                <small class="p-invalid p-error" v-if="v$.invoicegenerator.$error">{{
                                    v$.invoicegenerator.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-4">
                                <label for="system_activation_date">System Activation Date <span
                                        class="p-invalid p-error"> *</span></label>
                                <Calendar :showIcon="true" :monthNavigator="true" :yearNavigator="true"
                                    v-model="system_activation_date" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                                    appendTo="body" :manualInput="false"></Calendar>
                                <small class="p-invalid p-error" v-if="v$.system_activation_date.$error">{{
                                    v$.system_activation_date.$errors[0].$message }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="clientstatus">
                                    Client Status
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <Dropdown id="clientstatus" v-model="clientstatus" :options="clientstatusList"
                                    optionLabel="label" placeholder="Select ..." appendTo="body"></Dropdown>
                                <small class="p-invalid p-error" v-if="v$.clientstatus.$error">{{
                                    v$.clientstatus.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-4"></div>
                            <div class="p-field p-col-4"></div>
                            <div class="p-field p-col-12 p-md-4">
                                <div class="p-fluid p-formgrid p-grid">
                                    <div class="p-field p-col-12 p-md-12">
                                        <label for="image">
                                            Party Logo
                                            <span class="p-invalid p-error">*</span>
                                        </label>
                                        <FileUpload mode="basic" ref="clientlogo" :maxFileSize="5000000"
                                            accept="image/*" chooseLabel="Upload Logo" auto
                                            @select="handleClientLogoUpload" />
                                        <small class="p-invalid p-error" v-if="clientlogoError">{{ clientlogoerror
                                            }}</small>
                                        <!-- <small class="p-invalid p-error" v-if="v$.clientlogo.$error">{{
                                            v$.clientlogo.$errors[0].$message
                                        }}</small> -->
                                        <div v-if="clientlogoUrl && clientlogoUrl.url">
                                            <img v-if="clientlogoUrl && clientlogoUrl.url" class="p-pt-2"
                                                role="presentation" :alt="clientlogoUrl.name" :src="clientlogoUrl.url"
                                                width="120" height="120" />
                                            <button @click="removeClientLogo()"
                                                class="p-button p-button-danger p-button-rounded"
                                                style="position: relative; right: 10px;width: 18px;top: -100px; min-width: 18px !important; padding: 5px;">
                                                <i class="pi pi-times" style="font-size: 8px;"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- party leader logo -->
                            <!-- <div class="p-field p-col-12 p-md-4">
                                <div class="p-fluid p-formgrid p-grid">
                                    <div class="p-field p-col-12 p-md-12">
                                        <label for="image">
                                            Party President
                                            <span class="p-invalid p-error"
                                                v-if="(this.partyleaderlogo == null || this.partyleaderlogo == '') && (this.partyLogoUrl == null)">*</span>
                                        </label>
                                        <FileUpload mode="basic" ref="partylogo" :maxFileSize="5000000" accept="image/*"
                                            chooseLabel="Upload Logo" auto @select="handlePartyLogoUpload" />
                                        <small class="p-invalid p-error" v-if="partylogoerror">{{ partylogoerror
                                            }}</small>
                                        <small class="p-invalid p-error" v-if="v$.partylogo.$error">{{
                                            v$.partylogo.$errors[0].$message
                                        }}</small>
                                        <div>
                                            <img v-if="partyLogoUrl && partyLogoUrl.url" class="p-pt-2"
                                                role="presentation" :alt="partyLogoUrl.name" :src="partyLogoUrl.url"
                                                width="120" height="120" />
                                        </div>

                                    </div>
                                </div>
                            </div> -->
                            <div class="p-field p-col-12 p-md-4">
                                <div class="p-fluid p-formgrid p-grid">
                                    <div class="p-field p-col-12 p-md-12">
                                        <label for="image">
                                            Party President
                                            <span class="p-invalid p-error"
                                                v-if="(this.partyleaderlogo == null || this.partyleaderlogo == '') && (this.partyLogoUrl == null)">
                                                *
                                            </span>
                                        </label>
                                        <FileUpload mode="basic" ref="partylogo" :maxFileSize="5000000" accept="image/*"
                                            chooseLabel="Upload Logo" auto @select="handlePartyLogoUpload" />

                                        <small class="p-invalid p-error" v-if="partylogoerror">
                                            {{ partylogoerror }}
                                        </small>
                                        <small class="p-invalid p-error" v-if="v$.partylogo.$error">
                                            {{ v$.partylogo.$errors[0].$message }}
                                        </small>
                                        <div v-if="partyLogoUrl && partyLogoUrl.url">
                                            <img class="p-pt-2" role="presentation" :alt="partyLogoUrl.name"
                                                :src="partyLogoUrl.url" width="120" height="120" />
                                            <button @click="removePartyLogo()"
                                                class="p-button p-button-danger p-button-rounded"
                                                style="position: relative; right: 10px;width: 18px;top: -100px; min-width: 18px !important; padding: 5px;">
                                                <i class="pi pi-times" style="font-size: 8px;"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- party sub leader logo -->
                            <div class="p-field p-col-12 p-md-4">
                                <div class="p-fluid p-formgrid p-grid">
                                    <div class="p-field p-col-12 p-md-12">
                                        <label for="image">
                                            Current CM
                                            <span class="p-invalid p-error"
                                                v-if="(this.partylogo == null || this.partylogo == '') && (this.partyleaderlogoUrl == null)">*</span>
                                        </label>
                                        <FileUpload mode="basic" ref="partyleaderlogo" :maxFileSize="5000000"
                                            accept="image/*" chooseLabel="Upload Logo" auto
                                            @select="handlePartyLeaderLogoUpload" />
                                        <small class="p-invalid p-error" v-if="partyleaderlogoerror">{{
                                            partyleaderlogoerror
                                            }}</small>
                                        <small class="p-invalid p-error" v-if="v$.partyleaderlogo.$error">{{
                                            v$.partyleaderlogo.$errors[0].$message
                                            }}</small>
                                        <div v-if="partyleaderlogoUrl && partyleaderlogoUrl.url">
                                            <img v-if="partyleaderlogoUrl && partyleaderlogoUrl.url" class="p-pt-2"
                                                role="presentation" :alt="partyleaderlogoUrl.name"
                                                :src="partyleaderlogoUrl.url" width="120" height="120" />
                                            <button @click="removePartyLeaderLogo()"
                                                class="p-button p-button-danger p-button-rounded"
                                                style="position: relative; right: 10px;width: 18px;top: -100px; min-width: 18px !important; padding: 5px;">
                                                <i class="pi pi-times" style="font-size: 8px;"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- party banner -->
                            <div class="p-field p-col-12 p-md-4">
                                <div class="p-fluid p-formgrid p-grid">
                                    <div class="p-field p-col-12 p-md-12">
                                        <label for="image">
                                            Party Banner
                                            <span class="p-invalid p-error">*</span>
                                        </label>
                                        <FileUpload mode="basic" ref="partybanner" :maxFileSize="5000000"
                                            accept="image/*" chooseLabel="Upload Banner" auto
                                            @select="handlepartybannerUpload" />
                                        <small class="p-invalid p-error" v-if="partybannererror">{{ partybannererror
                                            }}</small>
                                        <small class="p-invalid p-error" v-if="v$.partybanner.$error">{{
                                            v$.partybanner.$errors[0].$message
                                            }}</small>
                                        <div v-if="partybannerUrl && partybannerUrl.url">
                                            <img v-if="partybannerUrl && partybannerUrl.url" class="p-pt-2"
                                                role="presentation" :alt="partybannerUrl.name" :src="partybannerUrl.url"
                                                width="120" height="120" />
                                            <button @click="removePartyBanner()"
                                                class="p-button p-button-danger p-button-rounded"
                                                style="position: relative; right: 10px;width: 18px;top: -100px; min-width: 18px !important; padding: 5px;">
                                                <i class="pi pi-times" style="font-size: 8px;"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--accesscode banner image -->
                            <div class="p-field p-col-12 p-md-4">
                                <div class="p-fluid p-formgrid p-grid">
                                    <div class="p-field p-col-12 p-md-12">
                                        <label for="image">
                                            Access Code Banner Image
                                            <span class="p-invalid p-error">*</span>
                                        </label>
                                        <FileUpload mode="basic" ref="accesscodebannerimg" :maxFileSize="5000000"
                                            accept="image/*" chooseLabel="Upload Banner" auto
                                            @select="handleaccesscodebannerimgUpload" />
                                        <small class="p-invalid p-error" v-if="accesscodebannerimgerror">{{
                                            accesscodebannerimgerror
                                            }}</small>
                                        <small class="p-invalid p-error" v-if="v$.accesscodebannerimg.$error">{{
                                            v$.accesscodebannerimg.$errors[0].$message
                                            }}</small>
                                        <div v-if="accesscodebannerimgUrl && accesscodebannerimgUrl.url">
                                            <img v-if="accesscodebannerimgUrl && accesscodebannerimgUrl.url"
                                                class="p-pt-2" role="presentation" :alt="accesscodebannerimgUrl.name"
                                                :src="accesscodebannerimgUrl.url" width="120" height="120" />
                                            <button @click="removeAccesscodeBannerImg()"
                                                class="p-button p-button-danger p-button-rounded"
                                                style="position: relative; right: 10px;width: 18px;top: -100px; min-width: 18px !important; padding: 5px;">
                                                <i class="pi pi-times" style="font-size: 8px;"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- party Favicon -->
                            <div class="p-field p-col-12 p-md-4">
                                <div class="p-fluid p-formgrid p-grid">
                                    <div class="p-field p-col-12 p-md-12">
                                        <label for="image">
                                            Party Favicon
                                            <!-- <span class="p-invalid p-error">*</span> -->
                                        </label>
                                        <FileUpload mode="basic" ref="partyfavicon" :maxFileSize="5000000"
                                            accept="image/*" chooseLabel="Upload Favicon" auto
                                            @select="handlepartyfaviconUpload" />
                                        <small class="p-invalid p-error" v-if="partyfaviconerror">{{
                                            partyfaviconerror
                                            }}</small>
                                        <div v-if="partyfaviconUrl && partyfaviconUrl.url">
                                            <img v-if="partyfaviconUrl && partyfaviconUrl.url" class="p-pt-2"
                                                role="presentation" :alt="partyfaviconUrl.name"
                                                :src="partyfaviconUrl.url" width="120" height="120" />
                                            <button @click="removePartyFavicon()"
                                                class="p-button p-button-danger p-button-rounded"
                                                style="position: relative; right: 10px;width: 18px;top: -100px; min-width: 18px !important; padding: 5px;">
                                                <i class="pi pi-times" style="font-size: 8px;"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="p-d-flex p-pt-4">
                        <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                            @click="saveClientStepFirst" style="width: 90px"></Button>
                        <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color"
                            class="p-button-text p-ml-auto" style="width: 90px"></Button>
                    </div>
                </div>
                <div v-if="showloaderpage" style="height: 390px; position: relative">
                    <div class="custom-modal-spinner-loader">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- image preview dialog start here -->
    <Dialog v-model:visible="ImagePreviewDialog" :maximizable="true" :style="{ width: '400px' }" header="Preview"
        :modal="true" class="p-fluid whatsapp-preview-outer">
        <div class="p-fluid p-formgrid p-grid">
            <img :src="previewimg" class="modal-preview-image" />
        </div>
    </Dialog>
    <!-- image preview dialog start here -->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import router from '@/router';
import { required, helpers, email, numeric, maxLength, minLength, requiredIf } from '@vuelidate/validators';
import moment from "moment";

export default {
    data() {
        return {
            v$: useValidate(),
            clientname: '',
            clientshortname: '',
            partyname: '',
            clientlogo: '',
            clientlogoError: '',
            clientlogoUrl: '',
            partyLogoUrl: '',
            partylogo: '',
            partylogoerror: '',
            partyleaderlogoUrl: '',
            partyleaderlogo: '',
            partyleaderlogoerror: '',
            partybannerUrl: '',
            partybanner: '',
            partybannererror: '',
            partyfaviconUrl: '',
            partyfavicon: '',
            partyfaviconerror: '',
            accesscodebannerimgUrl: null,
            accesscodebannerimg: null,
            accesscodebannerimgerror: null,
            officeaddress: '',
            state: '',
            stateList: [],
            partynameList: [],
            city: [],
            cityList: [],
            officemobileno: '',
            officeemail: '',
            invoicegenerator: '',
            clientstatus: '',
            clientstatusList: [
                { label: 'Active', value: 0 },
                { label: 'Soft Suspension', value: 1 },
                { label: 'Temporary Suspension', value: 2 },
                { label: 'Suspended', value: 3 },
                { label: 'Deletion Notice Period', value: 4 },
                { label: 'Permanent Suspended', value: 5 },
                { label: 'Deleted', value: 6 },
                { label: 'Under Maintenance', value: 7 },
            ],
            clientInfo: [],
            submitted: false,
            showLoader: false,
            ImagePreviewDialog: false,
            maa6: '',
            maa47: '',
            showloaderpage: false,
            loksabha: '',
            loksabhaList: [],
            vidhansabha: '',
            vidhansabhaList: [],
            citySuggestions: [],
            loksabhaSuggestions: [],
            vidhansabhaSuggestions: [],
            storage_path: '',
            routeParam: '',
            system_activation_date: null,
        };
    },
    validations() {
        return {
            clientname: { required: helpers.withMessage('Please enter candidate name', required) },
            partyname: { required: helpers.withMessage('Please enter party name', required) },
            clientshortname: { required: helpers.withMessage('Please enter client short name', required) },
            partylogo: {
                requiredIf: helpers.withMessage('Please upload party leader logo', requiredIf((this.partyleaderlogo == null || this.partyleaderlogo == '') && (this.partyLogoUrl == null)))
            },
            partyleaderlogo: {
                requiredIf: helpers.withMessage('Please upload party sub-leader logo', requiredIf((this.partylogo == null || this.partylogo == '') && (this.partyleaderlogoUrl == null))),
            },
            partybanner: {
                requiredIf: helpers.withMessage('Please upload party banner', requiredIf(
                    this.partybannerUrl == null
                )),
            },
            accesscodebannerimg: {
                requiredIf: helpers.withMessage('Please upload banner image', requiredIf(
                    this.accesscodebannerimgUrl == null
                )),
            },
            clientlogo: {
                requiredIf: helpers.withMessage('Please upload party logo', requiredIf(
                    this.clientlogoUrl == null
                )),
            },
            officeaddress: { required: helpers.withMessage('Please enter office address', required) },
            state: { required: helpers.withMessage('Please select state', required) },
            city: { required: helpers.withMessage('Please select city', required) },
            vidhansabha: { required: helpers.withMessage('Please select vidhan sabha', required) },
            loksabha: { required: helpers.withMessage('Please select lok sabha', required) },
            officeemail: { required: helpers.withMessage('Please enter email address', required), email: helpers.withMessage('Email address is invalid', email) },
            officemobileno: { required: helpers.withMessage('Please enter mobile number', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(10), maxLength: maxLength(10) },
            invoicegenerator: { required: helpers.withMessage('Please select option', required) },
            clientstatus: { required: helpers.withMessage('Please select client status', required) },
            system_activation_date: { required: helpers.withMessage('Please select date', required) },
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    watch: {
        loksabha(newValue) {
            if (newValue) {
                this.getVidhanSabhaList({ value: newValue.map(d => d.value).join() })
            }
        }
    },
    mounted() {
        const route = useRoute();
        this.routeParam = route.params.clientSubId;
        this.getParties();
        this.getStates();
        this.getclientdetails({ maa1: this.routeParam });
        this.storage_path = JSON.parse(localStorage.masterBucketName);
    },
    methods: {
        onStateSelect(e) {
            this.city = [];
            this.loksabha = '';
            this.vidhansabha = '';
            this.vidhansabhaList = [];
            this.selectclientcity(e.value);
            this.getLokSabhaList(e.value);
        },
        // validateInput(event) {
        //     const regex = /^[^0-9]+$/; // Regular expression to allow everything except numbers
        //     if (!regex.test(event.key)) {
        //         event.preventDefault(); // Prevents input of characters that don't match the regex
        //     }
        // },
        getStates(ev) {
            this.ApiService.getStates(ev).then((data) => {
                if (data.status == 200) {
                    this.stateList = data.data;
                } else {
                    this.stateList = '';
                }
            });
        },
        getParties(ev) {
            this.ApiService.getParties(ev).then((data) => {
                if (data.status == 200) {
                    this.partynameList = data.data;
                } else {
                    this.partynameList = '';
                }
            });
        },
        selectclientcity(ev) {
            this.getCity(ev);
        },
        getCity(e) {
            if (e.value) {
                this.ApiService.getcities({ state_id: e.value }).then((items) => {
                    if (items.status === 200) {
                        this.cityList = items.data;
                    }
                });
            }
        },
        getclientdetails(item) {
            this.showloaderpage = true;
            this.ApiService.getclientdetails(item).then((data) => {
                if (data.status == 200) {
                    this.clientInfo = data.data.records;
                    this.clientid = this.clientInfo.maa1;
                    this.clientname = this.clientInfo.maa2;
                    this.clientshortname = this.clientInfo.maa65;
                    if (this.clientInfo.maa4) {
                        this.partyname = { value: this.clientInfo.maa3, label: this.clientInfo.maa4 };

                    }
                    this.officemobileno = this.clientInfo.maa17;
                    this.officeemail = this.clientInfo.maa18;
                    if (this.clientInfo.maa9) {
                        this.state = { value: this.clientInfo.maa8, label: this.clientInfo.maa9 };
                    }
                    if (this.clientInfo.maa12) {
                        this.ApiService.getcities({ state_id: this.state.value }).then((items) => {
                            if (items.status === 200) {
                                this.cityList = items.data;
                                let arrConverted = this.clientInfo.maa11.split(',');
                                for (let index = 0; index < arrConverted.length; index++) {
                                    let vals = this.cityList.filter(function (item) {
                                        return item.value == arrConverted[index];
                                    });
                                    if (vals.length > 0) {
                                        this.city = [...this.city, vals[0]];
                                    }
                                }
                            }
                        });
                    }
                    if (this.clientInfo.maa14) {
                        this.ApiService.getloksabha({ mbb4: this.state.value }).then((items) => {
                            if (items.status === 200) {
                                this.loksabhaList = items.data;
                                let arrConverted = this.clientInfo.maa13.split(',');
                                for (let index = 0; index < arrConverted.length; index++) {
                                    let vals = this.loksabhaList.filter(function (item) {
                                        return item.value == arrConverted[index];
                                    });
                                    if (vals.length > 0) {
                                        this.loksabha = [...this.loksabha, vals[0]];
                                    }
                                }
                            }
                        });
                    }
                    if (this.clientInfo.maa16) {
                        this.ApiService.getvidhansabha({ district_id: this.clientInfo.maa13 }).then((items) => {
                            if (items.status === 200) {
                                this.vidhansabhaList = items.data;
                                let arrConverted = this.clientInfo.maa15.split(',');
                                for (let index = 0; index < arrConverted.length; index++) {
                                    let vals = this.vidhansabhaList.filter(function (item) {
                                        return item.value == arrConverted[index];
                                    });
                                    if (vals.length > 0) {
                                        this.vidhansabha = [...this.vidhansabha, vals[0]];
                                    }
                                }
                            }
                        })
                    }

                    this.officeaddress = this.clientInfo.maa7;
                    this.invoicegenerator = this.clientInfo.maa20;
                    if (this.clientInfo.maa37 == 0) {
                        this.clientstatus = { value: 0, label: 'Active' };
                    }
                    if (this.clientInfo.maa37 == 1) {
                        this.clientstatus = { value: 1, label: 'Soft Suspension' };
                    }
                    if (this.clientInfo.maa37 == 2) {
                        this.clientstatus = { value: 2, label: 'Temporary Suspension' };
                    }
                    if (this.clientInfo.maa37 == 3) {
                        this.clientstatus = { value: 3, label: 'Suspended' };
                    }
                    if (this.clientInfo.maa37 == 4) {
                        this.clientstatus = { value: 4, label: 'Deletion Notice Period' };
                    }
                    if (this.clientInfo.maa37 == 5) {
                        this.clientstatus = { value: 5, label: 'Permanent Suspended' };
                    }
                    if (this.clientInfo.maa37 == 6) {
                        this.clientstatus = { value: 6, label: 'Deleted' };
                    }
                    if (this.clientInfo.maa37 == 7) {
                        this.clientstatus = { value: 7, label: 'Under Maintenance' };
                    }
                    this.clientlogoUrl = { name: this.clientInfo.maa6, url: this.clientInfo.maa6 && `https://storage.googleapis.com/${this.storage_path}/Client/Logo/${this.clientInfo.maa6}` };
                    this.partyLogoUrl = { name: this.clientInfo.maa47, url: this.clientInfo.maa47 && `https://storage.googleapis.com/${this.storage_path}/Client/PartyLogo/${this.clientInfo.maa47}` };
                    this.partyleaderlogoUrl = { name: this.clientInfo.maa66, url: this.clientInfo.maa66 && `https://storage.googleapis.com/${this.storage_path}/Client/PartyLeaderLogo/${this.clientInfo.maa66}` };
                    this.partybannerUrl = { name: this.clientInfo.maa59, url: this.clientInfo.maa59 && `https://storage.googleapis.com/${this.storage_path}/Client/PartyBanner/${this.clientInfo.maa59}` };
                    this.partyfaviconUrl = { name: this.clientInfo.maa61, url: this.clientInfo.maa61 && `https://storage.googleapis.com/${this.storage_path}/Client/PartyFavicon/${this.clientInfo.maa61}` };
                    this.accesscodebannerimgUrl = { name: this.clientInfo.maa63, url: this.clientInfo.maa63 && `https://storage.googleapis.com/${this.storage_path}/Client/AccesscodeBannerImg/${this.clientInfo.maa63}` };

                    if (data.aa11 != null) {
                        this.userprofilefile = data.aa11;
                    }
                    if (this.clientInfo.maa69 != null) {

                        this.system_activation_date = moment(this.clientInfo.maa69, 'YYYY-MM-DD').format("DD/MM/YYYY");
                    }
                    this.showloaderpage = false;
                }
                this.showloaderpage = false;
            });
        },
        selectedValues({ label, value }) {
            value = value.split(',');
            label = label.split(',')
            return value.map((d, i) => { return { label: label[i], value: d } })
        },
        saveClientStepFirst() {
            this.submitted = true;
            this.v$.$validate();
            var formData = new FormData();
            let selectedCitiesValues = this.city.map(d => d.value).join();
            let selectedCitiesLabels = this.city.map(d => d.label).join();
            let selectedLoksabhaValues = this.loksabha.map(d => d.value).join();
            let selectedLoksabhaLabels = this.loksabha.map(d => d.label).join();
            let selectedVidhansabhaValues = this.vidhansabha.map(d => d.value).join();
            let selectedVidhansabhaLabels = this.vidhansabha.map(d => d.label).join();
            formData.append('maa1', this.clientid);
            formData.append('maa2', this.clientname);
            formData.append('maa3', this.partyname.value);
            formData.append('maa4', this.partyname.label);
            formData.append('maa8', this.state.value);
            formData.append('maa9', this.state.label);
            formData.append('maa11', selectedCitiesValues);
            formData.append('maa12', selectedCitiesLabels);
            formData.append('maa13', selectedLoksabhaValues);
            formData.append('maa14', selectedLoksabhaLabels);
            formData.append('maa15', selectedVidhansabhaValues);
            formData.append('maa16', selectedVidhansabhaLabels);

            if (this.clientlogo != null && this.clientlogo != '')
                formData.append('maa6', this.clientlogo);
            if (this.partylogo != null && this.partylogo != '') {
                formData.append('maa47', this.partylogo);
            }
            if (this.partyleaderlogo != null && this.partyleaderlogo != '') {
                formData.append('maa66', this.partyleaderlogo);
            }
            formData.append('maa7', this.officeaddress);
            formData.append('maa17', this.officemobileno);
            formData.append('maa18', this.officeemail);
            formData.append('maa20', this.invoicegenerator);
            formData.append('maa37', this.clientstatus.value);
            if (this.partybanner != null && this.partybanner != '') {
                formData.append('maa59', this.partybanner);
            }

            if (this.accesscodebannerimg != null && this.accesscodebannerimg != '')
                formData.append('maa63', this.accesscodebannerimg);
            // console.log(this.partyfavicon);
            if (this.partyfavicon != null && this.partyfavicon != '')
                formData.append('maa61', this.partyfavicon);
            formData.append('maa65', this.clientshortname);
            if (this.system_activation_date != null && this.system_activation_date != '') {
                let system_activation_date = moment(this.system_activation_date).format("YYYY-MM-DD");
                if (system_activation_date != 'Invalid date' && this.system_activation_date != null) {
                    formData.append('maa69', system_activation_date);
                }
            }
            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ' - ' + pair[1]);
            // }
            if (!this.v$.$error) {
                this.showLoader = true;
                this.ApiService.createclient(formData).then((items) => {
                    if (items.status == 200) {
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.addUserDialogStatus = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.goToDashboard();
                        }, 4000);
                    } else {
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        handlePartyLogoUpload() {
            var partylogo = this.$refs.partylogo.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!partylogo) {
                this.partylogoerror = 'Please upload logo';
            } else if (!partylogo.size > (5 * 1024 * 1024)) {
                this.partylogoerror = 'File size cannot exceed more than 5MB';
            } else if (!partylogo.name.match(allowedExtensions)) {
                this.partylogoerror = 'Invalid file type';
            } else {
                this.partylogo = this.$refs.partylogo.files[0];
                this.partyLogoUrl = { name: this.partylogo.name, url: this.partylogo.objectURL };
                this.partylogoerror = '';
            }
        },
        handlePartyLeaderLogoUpload() {
            var partyleaderlogo = this.$refs.partyleaderlogo.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!partyleaderlogo) {
                this.partyleaderlogoerror = 'Please upload logo';
            } else if (!partyleaderlogo.size > (5 * 1024 * 1024)) {
                this.partyleaderlogoerror = 'File size cannot exceed more than 5MB';
            } else if (!partyleaderlogo.name.match(allowedExtensions)) {
                this.partyleaderlogoerror = 'Invalid file type';
            } else {
                this.partyleaderlogo = this.$refs.partyleaderlogo.files[0];
                this.partyleaderlogoUrl = { name: this.partyleaderlogo.name, url: this.partyleaderlogo.objectURL };
                this.partyleaderlogoerror = '';
            }
        },

        handleClientLogoUpload() {
            var clientlogo = this.$refs.clientlogo.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!clientlogo) {
                this.clientlogoerror = 'Please upload logo';
            } else if (!clientlogo.size > (5 * 1024 * 1024)) {
                this.clientlogoerror = 'File size cannot exceed more than 2MB';
            } else if (!clientlogo.name.match(allowedExtensions)) {
                this.clientlogoerror = 'Invalid file type';
            } else {
                this.clientlogo = this.$refs.clientlogo.files[0];
                this.clientlogoUrl = { name: this.clientlogo.name, url: this.clientlogo.objectURL };
                this.clientlogoerror = '';
            }
        },
        handlepartybannerUpload() {
            var partybanner = this.$refs.partybanner.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!partybanner) {
                this.partybannererror = 'Please upload logo';
            } else if (!partybanner.size > (5 * 1024 * 1024)) {
                this.partybannererror = 'File size cannot exceed more than 5MB';
            } else if (!partybanner.name.match(allowedExtensions)) {
                this.partybannererror = 'Invalid file type';
            } else {
                this.partybanner = this.$refs.partybanner.files[0];
                this.partybannerUrl = { name: this.partybanner.name, url: this.partybanner.objectURL };
                this.partybannererror = '';
            }
        },
        handleaccesscodebannerimgUpload() {
            var accesscodebannerimg = this.$refs.accesscodebannerimg.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!accesscodebannerimg) {
                this.accesscodebannerimgerror = 'Please upload logo';
            } else if (!accesscodebannerimg.size > (5 * 1024 * 1024)) {
                this.accesscodebannerimgerror = 'File size cannot exceed more than 5MB';
            } else if (!accesscodebannerimg.name.match(allowedExtensions)) {
                this.accesscodebannerimgerror = 'Invalid file type';
            } else {
                this.accesscodebannerimg = this.$refs.accesscodebannerimg.files[0];
                this.accesscodebannerimgUrl = { name: this.accesscodebannerimg.name, url: this.accesscodebannerimg.objectURL };
                this.accesscodebannerimgerror = '';
            }
        },
        handlepartyfaviconUpload() {
            var partyfavicon = this.$refs.partyfavicon.files[0];
            // console.log(partyfavicon);
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!partyfavicon) {
                this.partyfaviconerror = 'Please upload logo';
            } else if (!partyfavicon.size > (5 * 1024 * 1024)) {
                this.partyfaviconerror = 'File size cannot exceed more than 5MB';
            } else if (!partyfavicon.name.match(allowedExtensions)) {
                this.partyfaviconerror = 'Invalid file type';
            } else {
                this.partyfavicon = this.$refs.partyfavicon.files[0];
                this.partyfaviconUrl = { name: this.partyfavicon.name, url: this.partyfavicon.objectURL };
                this.partyfaviconerror = '';
            }
        },
        removePartyLogo() {
            this.partyLogoUrl = null;
            this.$refs.partylogo.clear();

            this.ApiService.removeClientLogo({ clientId: this.clientid, columnName: 'maa47' })
                .then((items) => {
                    if (items.success === true) {
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        // setTimeout(() => {
                        //     this.goToDashboard();
                        // }, 4000);
                    } else {
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
        },
        removePartyLeaderLogo() {
            this.partyleaderlogoUrl = null;
            this.$refs.partyleaderlogo.clear();

            this.ApiService.removeClientLogo({ clientId: this.clientid, columnName: 'maa66' })
                .then((items) => {
                    if (items.success === true) {
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        // setTimeout(() => {
                        //     this.goToDashboard();
                        // }, 4000);
                    } else {
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
        },
        removeClientLogo() {
            this.clientlogoUrl = null;
            this.$refs.clientlogo.clear();

            this.ApiService.removeClientLogo({ clientId: this.clientid, columnName: 'maa6' })
                .then((items) => {
                    if (items.success === true) {
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        // setTimeout(() => {
                        //     this.goToDashboard();
                        // }, 4000);
                    } else {
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
        },
        removePartyBanner() {
            this.partybannerUrl = null;
            this.$refs.partybanner.clear();

            this.ApiService.removeClientLogo({ clientId: this.clientid, columnName: 'maa59' })
                .then((items) => {
                    if (items.success === true) {
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        // setTimeout(() => {
                        //     this.goToDashboard();
                        // }, 4000);
                    } else {
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
        },
        removeAccesscodeBannerImg() {
            this.accesscodebannerimgUrl = null;
            this.$refs.accesscodebannerimg.clear();

            this.ApiService.removeClientLogo({ clientId: this.clientid, columnName: 'maa63' })
                .then((items) => {
                    if (items.success === true) {
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        // setTimeout(() => {
                        //     this.goToDashboard();
                        // }, 4000);
                    } else {
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
        },
        removePartyFavicon() {
            this.partyfaviconUrl = null;
            this.$refs.partyfavicon.clear();

            this.ApiService.removeClientLogo({ clientId: this.clientid, columnName: 'maa61' })
                .then((items) => {
                    if (items.success === true) {
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        // setTimeout(() => {
                        //     this.goToDashboard();
                        // }, 4000);
                    } else {
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
        },

        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (!this.officemobileno && !+$event.key) {
                $event.preventDefault();
            }

            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        goToDashboard() {
            router.push({
                name: "clientdashboard",
                params: { clientId: this.routeParam },

            });
            // window.open(routeData.href, "_self");
        },
        goToNext() {
            router.push({
                name: "clientconfig",
                params: { clientSubId: this.routeParam },

            });
            // window.open(routeData.href, "_self");
        },
        getLokSabhaList(ev) {
            this.ApiService.getloksabha({ mbb4: ev.value })
                .then((items) => {
                    if (items.success === true) {
                        this.loksabhaList = items.data;
                    }
                });
        },
        getVidhanSabhaList(ev) {
            // this.vidhansabha = '';
            this.ApiService.getvidhansabha({ district_id: ev.value })
                .then((items) => {
                    if (items.success === true) {
                        this.vidhansabhaList = items.data;
                    }
                });
        },
    },
};
</script>

<style scoped></style>
